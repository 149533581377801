import { Injectable } from '@angular/core';
import { ListasInterface, ListasTextInterface, ListasBooleanInterface } from 'src/app/interfaces';
import { validateVerticalPosition } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class SelectsService {
  tipos_perfis = [
    /*{id: 1, constante: 'ADMIN_EDP', descricao: 'EDP ADMIN'},
    {id: 2, constante: 'BOA_ENERGIA', descricao: 'BOA ENERGIA'},
    {id: 3, constante: 'RAMAL', descricao: 'RAMAL'},
    {id: 4, constante: 'COMISSIONAMENTO', descricao: 'COMISSIONAMENTO'},
    {id: 5, constante: 'MANUTENCAO', descricao: 'MANUTENÇÃO'},
    {id: 6, constante: 'PENDENCIAS', descricao: 'PENDÊNCIAS'}*/
    {id: 0, constante: 'ADMIN', descricao: 'ADMIN'},
    {id: 1, constante: 'FISCAL_DE_OBRAS', descricao: 'FISCAL DE OBRAS'},
    {id: 2, constante: 'BOA_ENERGIA', descricao: 'BOA ENERGIA'},
    {id: 3, constante: 'MANUTENCAO', descricao: 'MANUTENÇÃO'},
    {id: 4, constante: 'CSD', descricao: 'CSD'},
    {id: 5, constante: 'BACKOFFICE', descricao: 'BACKOFFICE'}


  ];
  tipos_ligacao: ListasInterface[] = [
        {value: 0, viewValue: 'LN - Ligação Nova'},
        {value: 1, viewValue: 'LE - Ligação Existente'},
        {value: 2, viewValue: 'MD - Modificação'},
        {value: 3, viewValue: 'RL - Religação'},
        {value: 4, viewValue: 'IP - Iluminação Pública'}
    ];
    tipos_pessoa: ListasTextInterface[] = [
        {value: 'FISICA', viewValue: 'Física'},
        {value: 'JURIDICA', viewValue: 'Jurídica'}
    ];
    resposta_simples: ListasInterface[] = [
        {value: 0, viewValue: 'NÃO'},
        {value: 1, viewValue: 'SIM'}
    ];
    resposta_simple_boolean: ListasBooleanInterface[]  = [
        {value: false, viewValue: 'NÃO'},
        {value: true, viewValue: 'SIM'}
    ];

    resposta_doar: ListasInterface[] = [
        {value: 0, viewValue: 'NÃO DOAR'},
        {value: 1, viewValue: 'DOAR'}
    ];
    resposta_doar_padrao_poste: ListasInterface[] = [
        //{value: 0, viewValue: 'Não'},
        {value: 5, viewValue: '5 metros'},
        {value: 7, viewValue: '7 metros'},
    ];

    resposta_doar_padrao_parede: ListasInterface[] = [
        {value: 0, viewValue: 'Sim'},
        {value: 1, viewValue: 'Nº Caixas'},
        {value: 2, viewValue: 'Kit interno'},
    ];
    resposta_estado_instalacao: ListasBooleanInterface[] = [
        {value: true, viewValue: 'Boa'},
        {value: false, viewValue: 'Ruim'}
    ];

    resposta_tipos_padrao: ListasInterface[] = [
        {value: 0, viewValue: 'Madeira'},
        {value: 1, viewValue: 'Parede'}
    ];

    constructor() { }

    getTiposPadrao(id){
      var res =  this.resposta_tipos_padrao.map((v) => (v.value == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : this.resposta_tipos_padrao[0];
    }

    getTiposLigacao(id){
      var res =  this.tipos_ligacao.map((v) => (v.value == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : this.tipos_ligacao[0];
    }
    getTiposPessoa(id){
      var res =  this.tipos_pessoa.map((v) => (v.value == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : this.tipos_pessoa[0];
    }
    getRespostaSimples(id){
      var res =  this.resposta_simples.map((v) => (v.value == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : this.resposta_simples[0];
    }
    getRespostaSimpleBoolean(id){
      var res =  this.resposta_simple_boolean.map((v) => (v.value == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : this.resposta_simple_boolean[0];
    }
    getRespostaDoar(id){
      var res = this.resposta_doar.map((v) => (v.value == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : this.resposta_doar[0];
    }
    getRespostaDoarPadraoPoste(id){
      var res =  this.resposta_doar_padrao_poste.map((v) => (v.value == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : this.resposta_doar_padrao_poste[0];
    }
    getRespostaDoarPadraoParede(id){
      var res =  this.resposta_doar_padrao_parede.map((v) => (v.value == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : this.resposta_doar_padrao_parede[0];
    }
    getRespostaEstadoInstalacao(id){
      var res =  this.resposta_estado_instalacao.map((v) => (v.value == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : this.resposta_estado_instalacao[0];
    }
    getTipoDePerfil(id){
      var res =  this.tipos_perfis.map((v) => (v.id == id || v.constante == id) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : null;
    }
    getTipoDePerfilString(constante: string){
      var res =  this.tipos_perfis.map((v) => (v.constante == constante) ? v : null).filter(function (el) {
        return el != null;
      });
      return (res.length > 0) ? res[0] : null;
    }

}
