import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { AngularMaterialModule } from './angular-material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AuthInterceptor, AuthService, ApiService } from './services';
import { ErrorInterceptor } from './services/auth/error.interceptor';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { LoadingComponent } from './layout/components/loading/loading.component';
import { CpfPipe, CnpjPipe, TelefonePipe } from './pipes';
import { ExtendDatePipe } from './pipes/extenddate.pipe';
import { UtilService } from './services/util/util.service';
import { CsModulosComponent } from './layout/components/cs-modulos/cs-modulos.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmDialogComponent } from './layout/components/confirmdialog/confirmdialog.component';
import { ImagensboaComponent } from './layout/components/imagensboa/imagensboa.component';
import { NotificationComponent } from './layout/notification/notification.component';
import { NotificationService } from './services/notification/notification.service';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        AngularMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(options),
        ScrollingModule,
        InfiniteScrollModule,
        NgxSpinnerModule
    ],
    entryComponents: [LoadingComponent,CsModulosComponent,ConfirmDialogComponent,NotificationComponent],
    declarations: [
        AppComponent,
        LoadingComponent,
        CsModulosComponent,
        ImagensboaComponent,
        CpfPipe,
        CnpjPipe,
        TelefonePipe,
        ConfirmDialogComponent,
        NotificationComponent
    ],
    providers: [
        AuthService,
        ApiService,
        UtilService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        /*{
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },*/
        AuthGuard,
        NotificationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
