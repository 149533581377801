import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';

@Pipe({
  name: 'date2'
})
export class ExtendDatePipe implements PipeTransform {
  
  constructor() {}

  transform(value: any): string {
    return String(value).replace(/(\d{2})\/(\d{2})\/(\d{4})/,"$3-$2-$1");
  }
}