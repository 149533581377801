import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AngularMaterialModule } from 'src/app/angular-material.module';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  dialogref: MatDialogRef<LoadingComponent>;
  msg = 'Carregando';
  constructor(public dialogRef: MatDialogRef<LoadingComponent>) {
    this.dialogref = dialogRef;
    this.dialogref.disableClose = true;
  }

  onNoClick(): void {
    //this.dialogRef.close();
  }

}
