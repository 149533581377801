import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpj'
})
export class CnpjPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // " "00"."000"."000"/"0000-00
    value = String(value).replace(/[^\d]+/g, '');
    value = String(value).substr(0, 14);
    //value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, '$1.$2.$3/$4-$5');
    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, '$1.$2.$3/$4-$5');
    console.log("!!!!!!!!!!!!")
    console.log(value)
    console.log("#############")
    return value;
  }
  // tslint:disable: radix
  validarCNPJ(s) {
    const cnpj = s.replace(/[^\d]+/g, '');

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14) {
      return false;
    }

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }

    // Cáculo de validação
    const t = cnpj.length - 2,
      d = cnpj.substring(t),
      d1 = parseInt(d.charAt(0)),
      d2 = parseInt(d.charAt(1)),
      calc = x => {
        let n = cnpj.substring(0, x),
          y = x - 7,
          s = 0,
          r = 0;

          for (let i = x; i >= 1; i--) {
            s += n.charAt(x - i) * y--;
            if (y < 2) {
              y = 9;
            }
          }

          r = 11 - s % 11;
          return r > 9 ? 0 : r;
      };

    return calc(t) === d1 && calc(t + 1) === d2;
  }

}
