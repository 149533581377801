import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CsModuloInterface } from 'src/app/interfaces/csmodulointerface';
import { Observable } from 'rxjs';
import { EnunsInterface, ListasInterface } from 'src/app/interfaces';
import { SelectsService } from 'src/app/services/selects/selects.service';
import { CsInterface } from 'src/app/interfaces/csinterface';

@Component({
    selector: 'app-cs-modulos',
    templateUrl: './cs-modulos.component.html',
    styleUrls: ['./cs-modulos.component.scss']
})
export class CsModulosComponent {
    posicoes = 12;
    csmodulos: CsModuloInterface[] = [];
    cs: CsInterface[] = [];
    csmodulosbkp: string;

    dialogref: MatDialogRef<CsModulosComponent>;
    numero_cs = 0;
    faseamento: EnunsInterface[] = [];
    TipoDeLigacao: ListasInterface[] = [];
    stateChangesFaseamento: Observable<any>[];
    cancelar = false;
    constructor(public dialogRef: MatDialogRef<CsModulosComponent>, private selects: SelectsService,) {
        this.dialogref = dialogRef;
        this.dialogref.disableClose = true;
        this.qtsmodulos();
        console.log('###########');
        console.log(this.csmodulos);
        console.log('###########');
        this.TipoDeLigacao = selects.tipos_ligacao;
    }

    ngOnInit() {

    }

    setCsmodulos(modulos: CsModuloInterface[] = []): void {
        const M: CsModuloInterface[] = modulos;
        this.csmodulos = M;
        this.csmodulosbkp = JSON.stringify(M);
        //alert(JSON.stringify(modulos))
    }

    salvar(): void {
        this.cancelar = false;
        console.log(this.numero_cs);
        this.dialogRef.close();
    }
    sair() {
        this.cancelar = true;
        //this.csmodulos = JSON.parse(this.csmodulosbkp);
        this.dialogRef.close();
    }

    qtsmodulos(): void {
        console.log('#############################');
        console.log(this.csmodulos);
        console.log(this.posicoes)
        console.log('#############################');


        this.csmodulos = this.csmodulos.map((value) => {
            if (value.tipo_de_servico == null) {
                value.tipo_de_servico = 0;
            }
            return value;
        });
        if (this.csmodulos.length > this.posicoes) {
            this.csmodulos = this.csmodulos.slice(0, this.posicoes);
        } else {
            for (let i = (this.csmodulos.length || 0); i < (this.posicoes); i++) {
                // tslint:disable-next-line: max-line-length
                this.csmodulos.push({
                    faseamento: 'MONOFASICO',
                    id: 0,
                    leitura_do_medidor_antigo: null,
                    medidor_antigo: '',
                    nota: '',
                    numero_do_display: '',
                    posicao: (i + 1),
                    disabled: false,
                    bloqueado: false,
                    anilha: null,
                    comissionado: false,
                    novomodulo: '',
                    observacoes_pendencias: '',
                    pendencia: false,
                    poste: '',
                    ramal: '',
                    tipo_de_servico: 0,
                    modulo1: '',
                    modulo2: '',
                    modulo3: '',
                    metragem_do_ramal: '',
                    remota: '',
                    remota_removida: false
                });
            }
        }
        this.csmodulos = this.sortedArray();
        for (let i = 0; i < this.csmodulos.length; i++) {
            this.csmodulos[i].bloqueado = false;
            switch (this.csmodulos[i].faseamento) {
                case 'MONOFASICO':
                    break;
                case 'BIFASICO':
                    const p1 = i;
                    [1].map(value => {
                        if (!this.csmodulos[p1 + value].bloqueado) {
                            this.csmodulos[p1 + value].bloqueado = false;
                            this.csmodulos[p1 + value].disabled = true;
                            i++;
                        }
                    });
                    break;
                case 'TRIFASICO':
                    const p = i;
                    [1, 2].map(value => {
                        if (!this.csmodulos[p + value].bloqueado) {
                            this.csmodulos[p + value].bloqueado = false;
                            this.csmodulos[p + value].disabled = true;
                            i++;
                        }
                    });
                    break;
            }
        }
    }
    getFaseamento(index: number, novo_valor) {
        const fase = this.csmodulos[index].faseamento;
        console.log(fase, novo_valor);
        if (this.validarPosicao(novo_valor, index)) {
            this.csmodulos[index].faseamento = fase;
            return;
        } else {
            this.csmodulos[index].faseamento = novo_valor;
        }

        switch (this.csmodulos[index].faseamento) {
            case 'MONOFASICO':
                if (this.csmodulos[index + 1].faseamento === 'BIFASICO') {
                    [1].map(value => {
                        this.csmodulos[index + value].disabled = false;
                        this.csmodulos[index + value].faseamento = 'MONOFASICO';
                    });
                }
                if (this.csmodulos[index + 1].faseamento === 'TRIFASICO') {
                    [1, 2].map(value => {
                        this.csmodulos[index + value].disabled = false;
                        this.csmodulos[index + value].faseamento = 'MONOFASICO';
                    });
                }
                break;
            case 'BIFASICO':
                if (this.validarPosicao('BIFASICO', index)) {
                    this.csmodulos[index].faseamento = fase;
                    return;
                }
                if (this.csmodulos[index + 1].faseamento === 'TRIFASICO') {
                    [1, 2].map(value => {
                        this.csmodulos[index + value].disabled = false;
                        this.csmodulos[index + value].faseamento = 'MONOFASICO';
                    });
                }
                [1].map(value => {
                    if (fase === 'TRIFASICO') {
                        [1, 2].map(value => {
                            this.csmodulos[index + value].disabled = false;
                            this.csmodulos[index + value].faseamento = 'MONOFASICO';
                        });
                    }
                    this.csmodulos[index + value].disabled = true;
                    this.csmodulos[index + value].faseamento = this.csmodulos[index].faseamento;
                    this.csmodulos[index + value].leitura_do_medidor_antigo = this.csmodulos[index].leitura_do_medidor_antigo;
                    this.csmodulos[index + value].medidor_antigo = this.csmodulos[index].medidor_antigo;
                    this.csmodulos[index + value].nota = this.csmodulos[index].nota;
                    this.csmodulos[index + value].numero_do_display = this.csmodulos[index].numero_do_display;

                });

                break;
            case 'TRIFASICO':
                if (this.validarPosicao('TRIFASICO', index)) {
                    this.csmodulos[index].faseamento = fase;
                    console.log(this.csmodulos);
                    return;
                }

                [1, 2].map(value => {
                    this.csmodulos[index + value].disabled = true;
                    this.csmodulos[index + value].faseamento = this.csmodulos[index].faseamento;
                    this.csmodulos[index + value].leitura_do_medidor_antigo = this.csmodulos[index].leitura_do_medidor_antigo;
                    this.csmodulos[index + value].medidor_antigo = this.csmodulos[index].medidor_antigo;
                    this.csmodulos[index + value].nota = this.csmodulos[index].nota;
                    this.csmodulos[index + value].numero_do_display = this.csmodulos[index].numero_do_display;
                });
                break;
        }
        // console.log(this.csmodulos);
    }
    bloquear(value: number) {
        if (this.csmodulos[value].bloqueado) {
            this.csmodulos[value].bloqueado = false;
            // this.csmodulos[value].disabled = false;
        } else if (!this.csmodulos[value].bloqueado) {
            this.csmodulos[value].bloqueado = true;
            // this.csmodulos[value].disabled = true;
        }
        console.log(this.csmodulos);
    }
    comissionar(value: number) {
        const comissionado = this.csmodulos[value].comissionado;
        this.csmodulos[value].comissionado = (comissionado) ? false : true;
        switch (this.csmodulos[value].faseamento) {
            case 'BIFASICO':
                [1].map(v2 => {
                    this.csmodulos[value + v2].comissionado = (comissionado) ? false : true;
                });
                break;
            case 'TRIFASICO':
                [0, 1, 2].map(v2 => {
                    this.csmodulos[value + v2].comissionado = (comissionado) ? false : true;
                });
                break;
        }
        console.log(this.csmodulos, this.csmodulos[value].faseamento);
    }
    log(index: number, key: string) {
        console.log(this.csmodulos[index][key]);
        switch (this.csmodulos[index].faseamento) {
            case 'MONOFASICO':
                break;
            case 'BIFASICO':
                if (this.validarPosicao('BIFASICO', index)) {
                    return;
                }
                [1].map(value => {
                    this.csmodulos[index + value][key] = this.csmodulos[index][key];
                });
                break;
            case 'TRIFASICO':
                if (this.validarPosicao('TRIFASICO', index)) {
                    return;
                }
                [1, 2].map(value => {
                    this.csmodulos[index + value][key] = this.csmodulos[index][key];
                });
                break;
        }
    }

    validarPosicao(faseamento: string, index) {
        switch (faseamento) {
            case 'MONOFASICO':
                return false;
                break;
            case 'BIFASICO':
                const b = [1].map(value => {
                    if (this.csmodulos[index + value].bloqueado) {
                        console.log(`Posição ${this.csmodulos[index + value].posicao} bloqueada.` + 'BIFASICO');
                        return value;
                    } else {
                        return null;
                    }
                }).filter(function (el) {
                    return el != null;
                });
                if (b.length > 0) {
                    // this.csmodulos[index].faseamento = 'MONOFASICO';
                    return true;
                } else {
                    return false;
                }
                break;
            case 'TRIFASICO':
                const c = [1, 2].map(value => {
                    if (this.csmodulos[index + value].bloqueado) {
                        console.log(`Posição ${this.csmodulos[index + value].posicao} bloqueada.` + 'TRIFASICO');
                        return value;
                    } else {
                        // this.csmodulos[index].faseamento = this.csmodulos[index+value].faseamento;
                        return null;
                    }
                }).filter(function (el) {
                    return el != null;
                });
                if (c.length > 0) {
                    return true;
                } else {
                    return false;
                }
                break;
        }
        return false;
    }

    sortedArray(): CsModuloInterface[] {
        this.csmodulos = this.csmodulos.sort((a, b) => {
            return a.posicao - b.posicao;
        });
        let x = 0;
        let total = this.cs.map( ( item : CsInterface, index ) => {
            if( index < this.numero_cs ) {
                return item.modulos.length;
            } else {
                return 0;
            }
        }).reduce((a, b) => a + b,0);

console.log("|||||||||||||||||||||||||||||||")
console.log(total);
console.log(this.csmodulos)
console.log("|||||||||||||||||||||||||||||||")
        this.csmodulos = this.csmodulos.map((item, pos) => {

            item.anilha =   ((total + 1) + pos);
            return item;
        });
        /*for (let i = total; i <= (total + this.posicoes); i++) {
            this.csmodulos[x].anilha = i;
            x++;
        }*/
        /*for (let i = (((this.numero_cs + 1) * 12) - 11); i <= ((this.numero_cs + 1) * 12); i++) {
            if(x < this.posicoes){
                this.csmodulos[x].anilha = i;
            }
            // this.csmodulos[x].posicao = (this.csmodulos[x].posicao + 1);
            x++;
        }*/
        return this.csmodulos;
    }

    numerocontrole(i): void {
        let value = this.csmodulos[i].nota + ' ';
        console.log(value)
        value = String(value).replace(/[^\d]+/g, '');
        value = value.replace(/(\d{5})(\d{4})/, '$1/$2');
        value = String(value).substr(0, 10);
        this.csmodulos[i].nota = value;
    }

}
