import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import {tap} from 'rxjs/operators';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService,public router: Router) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let dupReq;
    if (!request.url.includes('login')) {
      if (this.auth.tokenExpirado || (String(this.auth.authToken).length === 0) ) {
        localStorage.clear();
        this.router.navigate(['/']);
      }
      dupReq = request.clone({
        headers: request.headers.set('Authorization',  `Bearer ${this.auth.authToken}`),
      });
      return next.handle(dupReq);
    }
    return next.handle(request);
  }
  private modifyBody(body: any) {
    console.log(body);
    return body;
  }
}
