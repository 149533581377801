import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { NotificationComponent } from 'src/app/layout/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private dialog: MatDialog) {}

  private showNotification(message: string) {
    this.dialog.open(NotificationComponent, {
      disableClose: true,
      data: { message },
      //width: '400px',
      panelClass: 'notification-service-dialog'
    });
  }
  
  showNotificationLicencaVencida(){
    this.showNotification("Licença de uso Vencida");
  }

}
