import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    ROLE_PERMISSION = [
        {
            name: 'ADMIN',
            boaenergia:true,
            listaboaenergia:true,
            listaboaenergia2:true,
            ramal:true,
            agrupamento:true,
            listagrupamento:true,
            listaramal:true,
            usuario:true,
            listusuario:true,
            listlogs:true,
            materiais:true,
            uploadboaenergia:true
        },
        {
            name: 'FISCAL_DE_OBRAS',
            boaenergia:false,
            listaboaenergia:true,
            listaboaenergia2:true,
            ramal:true,
            agrupamento:false,
            listagrupamento:false,
            listaramal:true,
            usuario:false,
            listusuario:false,
            listlogs:false,
            materiais:false,
            uploadboaenergia:false
        },
        {
            name: 'BOA_ENERGIA',
            boaenergia:true,
            listaboaenergia:true,
            listaboaenergia2:true,
            ramal:false,
            agrupamento:false,
            listagrupamento:false,
            listaramal:false,
            usuario:false,
            listusuario:false,
            listlogs:false,
            materiais:false,
            uploadboaenergia:false
        },
        {
            name: 'MANUTENCAO',
            boaenergia:false,
            listaboaenergia:false,
            listaboaenergia2:false,
            ramal:false,
            agrupamento:false,
            listagrupamento:false,
            listaramal:false,
            usuario:false,
            listusuario:false,
            listlogs:false,
            materiais:false,
            uploadboaenergia:false
        },
        {
            name: 'CSD',
            boaenergia:false,
            listaboaenergia:false,
            listaboaenergia2:false,
            ramal:false,
            agrupamento:false,
            listagrupamento:false,
            listaramal:false,
            usuario:false,
            listusuario:false,
            listlogs:false,
            materiais:false,
            uploadboaenergia:false
        },
        {
            name: 'BACKOFFICE',
            boaenergia:false,
            listaboaenergia:true,
            listaboaenergia2:true,
            ramal:false,
            agrupamento:false,
            listagrupamento:true,
            listaramal:true,
            usuario:false,
            listusuario:true,
            listlogs:true,
            materiais:true,
            uploadboaenergia:true
        }
    ];

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const currentRoute = state.url.split('/')[1];
        if (localStorage.getItem('isLoggedin')) {
            if(this.checkPermission(currentRoute)){
                return true;
            }
            //this.router.navigate(['/']);
            return true;
        }

        this.router.navigate(['/login']);



        return false;
    }

    checkPermission(currentRoute: string) {
        let me = (localStorage.me)? JSON.parse(localStorage.me) : null;
        const permissionByRole = this.ROLE_PERMISSION.find(role => role.name === me.perfil);
        return permissionByRole[currentRoute];
      }
}
